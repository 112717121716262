/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';
import GlobalContext from 'hooks/contexts/GlobalContext';
import FormStep from 'components/form-wizards/basic-form/FormStep';
import DefaultErrorBoundary from 'components/error-boundaries/DefaultErrorBoundary';
import StepsWithSelections from 'components/base/steps/StepsWithSelections';
import ClickPortalFormContainer from './ClickPortalFormContainer';
import useMultiFormStateSync from './utils/useMultiFormStateSync';

function Form(props) {
  const {
    currentStepIndex,
    currentQuestions,
    isFirstStep,
    formStatus,
    formValues,
    steps,
    showSteps,
    title,
  } = props;

  useMultiFormStateSync(props);

  const {
    refs: { clickPortalSearchResultsRef },
  } = useContext(GlobalContext);

  const stepContainerClass = cx({
    basicFormWizard__stepContainer: true,
  });

  return (
    <div
      className={
        isFirstStep
          ? 'basicFormWizard basicFormWizard--isClickPortal basicFormWizard--isFirstStep'
          : 'basicFormWizard basicFormWizard--isClickPortal'
      }
    >
      {showSteps && clickPortalSearchResultsRef && (
        <div className="basicFormWizard__head">
          <StepsWithSelections
            currentStepIndex={
              steps ? steps[currentStepIndex]?.trueStepIndex : 0
            }
            steps={steps}
            stepsContainerRef={clickPortalSearchResultsRef}
            formValues={formValues}
          />
        </div>
      )}
      <div className="basicFormWizard__container">
        <h1 className="basicFormWizard__title">{title}</h1>
        <div className={stepContainerClass}>
          <DefaultErrorBoundary
            meta={{ currentStepIndex }}
            key={currentStepIndex}
          >
            <FormStep
              questions={currentQuestions}
              stepLabel={steps[currentStepIndex]?.groupLabel}
              stepIndex={currentStepIndex}
              formIsDirty={formStatus.isDirty}
            />
          </DefaultErrorBoundary>
        </div>
      </div>
    </div>
  );
}

export default function ClickPortalSidebarForm(props) {
  return <ClickPortalFormContainer Form={Form} {...props} />;
}

ClickPortalSidebarForm.propTypes = {
  formOptions: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
};
