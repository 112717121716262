/**
 * TODO: [T1-9336] write end to end test for this form once in production
 * This is the main form for MicroPortal
 */
import React, { useContext } from 'react';
import GlobalContext from 'hooks/contexts/GlobalContext';
import FullPageFormWizard from '../fullPageFormWrapper/FullPageFormWizard';
import useMicroPortalFormHandler from './useMicroPortalFormHandler';

/**
 * The Form component that is feed into the FormContainer.
 * This Form's props will be all form utilities and form values.
 */
export default function MicroPortalForm(props) {
  const {
    microPortal: { primaryFormSubmitPollingHelper },
    actions: { updateMicroPortalData },
  } = useContext(GlobalContext);

  const { onLogProgress, onOptionsRequest, onFormSubmit } =
    useMicroPortalFormHandler({
      updateMicroPortalData,
      primaryFormSubmitPollingHelper,
    });

  return (
    <FullPageFormWizard
      {...props}
      customSubmitHandler={onFormSubmit}
      onOptionsRequest={onOptionsRequest}
      onLogProgress={onLogProgress}
    />
  );
}
