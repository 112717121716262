import { isNextStepDynamic } from 'components/form-wizards/basic-form/utils';
import {
  doPreviousStepsHaveVisibleQuestions,
  getValidQuestions,
} from 'utils/formValuesUtils';
import { HISTORY_PAGE_ID } from 'consts';

/**
 * @summary this function is used to push the user to the next step, note that is should never be called on the last step
 * @param {Object} state - current state
 * @param {Object} action.shouldSkipSteps - skip the step if its already answered
 */
export default function moveToStep(state, action = {}) {
  const {
    shouldSkipSteps,
    shouldStopAtQuestions = [],
    isNonUserAction,
    isResumeSessionAcknowledged,
    nextStepIndex,
  } = action;
  const { formConfigs } = state;
  const totalSteps = formConfigs.steps.length;
  const step = formConfigs.steps[nextStepIndex];

  if (!step) {
    return state;
  }

  // at this point we need to get all valid question for the next step we want to move to
  const nextStepQuestions = getValidQuestions(
    step.questions,
    state.formValues,
    state.allQuestionsInForm,
    state.formStatus.dynamicOptions
  );

  // We want to keep track of movement within the form as page views, note this must stay in between the 2 recursive calls
  try {
    if (
      window.history.state.currentStepIndex !== nextStepIndex &&
      !isNonUserAction
    ) {
      // prevents the browser from automatically scrolling to the previous location on load
      if (window && window.history.scrollRestoration) {
        window.history.scrollRestoration = 'manual';
      }

      window.history.pushState(
        { currentStepIndex: nextStepIndex, id: HISTORY_PAGE_ID },
        ''
      );
    }
  } catch (error) {
    /* do nothing */
  }

  const updatedState = {
    ...state,
    title: step.title || formConfigs.title,
    subTitle: step.subTitle || formConfigs.subTitle,
    currentStepIndex: nextStepIndex,
    isLastStep: step === formConfigs.steps[totalSteps - 1],
    isFirstStep: !doPreviousStepsHaveVisibleQuestions(state),
    currentQuestions: nextStepQuestions,
    totalSteps,
    ctaText: step.ctaText || 'Continue',
    currentAction: 'next',
    isNextStepDynamic: isNextStepDynamic(state, nextStepIndex),
    formStatus: {
      ...state.formStatus,
      isResumeSessionAcknowledged:
        isResumeSessionAcknowledged ||
        state.formStatus.isResumeSessionAcknowledged,
      // if this is true we will keep skipping questions up to an empty one
      isResumingMode: !!shouldStopAtQuestions.length && shouldSkipSteps,
    },
  };

  updatedState.isFirstStep = !doPreviousStepsHaveVisibleQuestions(updatedState);

  return updatedState;
}
