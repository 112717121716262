// TODO: Unit test all reducer functions

import { isNextStepDisabled } from 'utils/formValuesUtils';
import initializeState from './reducer-functions/initializeState';
import moveBack from './reducer-functions/moveBack';
import moveToNextStep from './reducer-functions/moveToNextStep';
import setDynamicOptions from './reducer-functions/setDynamicOptions';
import updateFormValue from './reducer-functions/updateFormValue';
import updatedFormValues from './reducer-functions/updateFormValues';
import moveToEndOfForm from './reducer-functions/moveToEndOfForm';
import { setFieldLevelValidationStatus } from './reducer-functions/setFieldLevelValidationStatus';

export const initialState = {
  title: '',
  subTitle: '',
  isLastStep: true,
  isFirstStep: true,
  currentQuestions: [],
  totalSteps: 0,
  currentStepIndex: 0,
  ctaText: '',
  isThankyouPage: false,
  /** {[questionId]: { status: { validationStatus }}} */
  formValuesV2: {},
  // user form input values
  formValues: {},
  // for metadata. only ever mutate these values
  formStatus: {
    isSubmitting: false,
    isDirty: false,
    isInitializing: true,
    isFormResumeEligible: false, // true if we should pop open the modal
    isResumeSessionAcknowledged: false, // true if the user interacted with session modal
    isResumingMode: false, // this is true to indicate form should fast forward after some steps are taken
    isProcessing: true, // any processing such as requesting options for a question
    isFormPrefilled: false, // true if the user's passed session info was added to the form
    isLoadingActive: false, // T1-6263: The UI should not show the loading spinner based off the state of the form but the user's action with the work in a certain state.
    errors: {},
    leadEvalToken: null, // used on microsites to speed up lead submit. Fetched when form is complete and valid
    dynamicOptions: {},
    processingCount: 0, // to support us making more then 1 processing action on the form
    linkedSessionFormValues: {},
    /**
     * Keeps track of each step index that was fully complete
     * @type {Object<number, boolean>}
     */
    completedSteps: {},
    /**
     * @type {boolean} true if the step will auto advance once done processing
     */
    willAutoAdvance: false,
    /**
     * @type {boolean} true if the form is complete and all fields passed validation
     */
    isFormCompleteAndValid: false,
  },
  // Never mutate these form values used for calculate
  formConfigs: {
    steps: [],
  },
  allQuestionsInForm: {},
  allDynamicOptionQuestions: [],
  fieldNameMap: {},
  dispatchedActions: [],
};

// Reducer to update hook state
export function reducer(state, action) {
  const { type, ...args } = action;

  // Doing this to support Side Effects
  const _state = {
    ...state,
    dispatchedActions: [...state.dispatchedActions, action],
  };

  switch (type) {
    case 'INIT':
      return initializeState(_state, action.formConfigs);
    case 'MOVE_TO_NEXT_STEP':
      return moveToNextStep(_state, {
        isNonUserAction: action.isNonUserAction,
      });
    case 'MOVE_TO_THANK_YOU':
      return moveToNextStep(_state, { isThankyouPage: true });
    case 'MOVE_BACK':
      return moveBack(_state);
    case 'SET_FIELD_VALUE':
      return updateFormValue(_state, action);
    case 'UPDATE_FORM_VALUES': {
      return updatedFormValues(_state, action);
    }
    case 'RESUME_FORM': {
      return moveToNextStep(_state, action); // this will fast forward the form due to action.shouldSkipSteps being true;
    }
    case 'SET_STATUS': {
      let _processingCount = _state.formStatus.processingCount;

      if (args.isProcessing === true) {
        _processingCount += 1;
      }
      if (args.isProcessing === false) {
        _processingCount -= 1;
      }
      if (_processingCount < 0) {
        _processingCount = 0;
      }

      // Check for any errors and set field level Error, especially if they are pending
      const newState = {
        ..._state,
        formStatus: {
          ..._state.formStatus,
          ...args,
          processingCount: _processingCount,
          isProcessing: _processingCount > 0,
        },
      };

      const { isLoadingActive } = newState.formStatus;

      // Disable Loading Spinner if its active but the next step is not disabled anymore
      if (isLoadingActive && !isNextStepDisabled(newState)) {
        newState.formStatus.isLoadingActive = false;
      }
      return newState;
    }
    case 'SET_DYNAMIC_OPTIONS': {
      return setDynamicOptions(_state, action);
    }
    case 'MOVE_TO_END_OF_FORM': {
      return moveToEndOfForm(_state);
    }
    case 'SET_FIELD_VALIDATION_STATUS': {
      return setFieldLevelValidationStatus(_state, action);
    }
    default:
      throw new Error('Unknown Action on useBasicFormWizardHandler hook!');
  }
}
