/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';

function Checkbox({
  animatedLabel,
  disabled = false,
  fieldRef,
  hasDefault,
  id,
  isFocused,
  label,
  name,
  onChange,
  showCityState,
  value = '',
  valueTransform = (v) => v,
  ...props
}) {
  const _onChange = (event) => {
    onChange(valueTransform(event.target.value), {
      name: event.target.name,
      event,
    });
  };

  return (
    <div className="checkbox">
      <input
        {...props}
        ref={fieldRef}
        value={value}
        name={name}
        id={id}
        aria-label={name}
        onChange={_onChange}
        type="checkbox"
        disabled={disabled}
        className="checkbox__input"
      />
      <label htmlFor={id} className="checkbox__label">
        <span className="checkbox__box" />
        <span className="checkbox__labelText">{label}</span>
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  animatedLabel: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fieldRef: PropTypes.shape({}),
  hasDefault: PropTypes.bool,
  id: PropTypes.string,
  isFocused: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  showCityState: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  valueTransform: PropTypes.func,
};

export default Checkbox;
