import React, { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { NavItemProps } from 'utils/propTypes';
import cx from 'utils/classnames';
import { BREAKPOINT_MAP } from 'consts';
import GlobalContext from 'hooks/contexts/GlobalContext';
import useOutsideClick from 'hooks/custom/useOutsideClick';
import useCSSsettings from 'hooks/custom/forms/useCSSsettings';
import MegaMenuNav from './MegaMenuNav';
import useMegaMenu from './useMegaMenu';
import MegaMenuContext from './MegaMenuContext';

const MegaMenu = ({ navItems, onMenuClick, SecondaryNav }) => {
  const {
    navState = {},
    setNavState,
    handlers,
    getSelectedStatus,
    getNextMenuSections,
  } = useMegaMenu();

  const { handleOutsideClick, handleNavToggle } = handlers;

  const { navLevel } = navState;

  const {
    windowSize: { currentBreakpoint },
    flags: { navIsOpen },
  } = useContext(GlobalContext);

  const megaMenuRef = useRef(null);
  const settings = useCSSsettings(megaMenuRef);

  useOutsideClick(megaMenuRef, handleOutsideClick);

  const megaMenuClass = cx({
    megaMenu: true,
    [`megaMenu--level${navLevel}`]: !isNil(navLevel),
    [`megaMenu--isOpen`]: navIsOpen,
    [`megaMenu--hasSecondaryNav`]: !!SecondaryNav,
  });
  if (!navItems?.length > 0) {
    return null;
  }
  return (
    <MegaMenuContext.Provider
      value={{
        navState,
        setNavState,
        handlers,
        getSelectedStatus,
        getNextMenuSections,
        settings: { megaMenuAnimationSpeed: settings?.megaMenuAnimationSpeed },
      }}
    >
      <div className={megaMenuClass} ref={megaMenuRef} role="navigation">
        {(currentBreakpoint === BREAKPOINT_MAP.MOBILE.label ||
          currentBreakpoint === BREAKPOINT_MAP.TABLET.label) && (
          <button
            className="megaMenu__btnToggle"
            type="button"
            onClick={handleNavToggle}
          >
            <span className="isVisuallyHidden">
              {navIsOpen ? 'Close' : 'Open'}
            </span>
            <span className="megaMenu__btnToggleIcon">
              <span />
            </span>
          </button>
        )}
        {/* buildMegaMenu(navItems) */}
        <MegaMenuNav
          key={navItems[0].id}
          navItems={navItems}
          handlers={handlers}
          navState={navState}
          onMenuClick={onMenuClick}
          currentBreakpoint={currentBreakpoint}
          SecondaryNav={SecondaryNav}
        />
      </div>
    </MegaMenuContext.Provider>
  );
};

MegaMenu.propTypes = {
  navItems: PropTypes.arrayOf(NavItemProps),
  onMenuClick: PropTypes.func,
  SecondaryNav: PropTypes.node,
};

MegaMenu.defaultProps = {
  onMenuClick: () => {},
};

export default MegaMenu;
