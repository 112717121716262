import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  CtaPropTypes,
  FontAdjustmentPropTypes,
  WPImagesPropType,
} from 'utils/propTypes';
import cx from 'utils/classnames';
import { capitalizeFirstLetter } from 'utils/stringHelpers';
import CtaButton from 'components/blocks/ctaButton/CtaButton';
import ResponsiveImage from 'components/base/responsiveImage/ResponsiveImage';
import DefaultErrorBoundary from 'components/error-boundaries/DefaultErrorBoundary';
import GlobalContext from 'hooks/contexts/GlobalContext';

export default function HeroShort({
  background,
  customCssClass,
  cta,
  variation,
  images,
  title,
  subTitle,
  titleFontAdjustment,
  subtitleFontAdjustment,
  heroRef,
}) {
  const {
    windowSize: { currentBreakpoint },
  } = useContext(GlobalContext);

  const responsiveImageSizes = {
    DEFAULT: '100vw',
  };

  const heroClassName = cx({
    heroShort: true,
    [`heroShort--variation${
      variation ? capitalizeFirstLetter(variation) : ''
    }`]: !!variation,
    [`heroShort--background${
      background ? capitalizeFirstLetter(background) : ''
    }`]: !!background,
    [`heroShort--${customCssClass}`]: !!customCssClass,
  });

  return (
    <div className={heroClassName} ref={heroRef}>
      <div className="heroShort__inner">
        <div className="heroShort__content">
          <div className="heroShort__imgContainer">
            <div className="heroShort__imgContainerInner">
              <DefaultErrorBoundary meta={{ component: 'ResponsiveImage' }}>
                <ResponsiveImage
                  images={images}
                  className="heroShort__img"
                  sizes={responsiveImageSizes}
                  fetchpriority="high"
                />
              </DefaultErrorBoundary>
            </div>
          </div>
          <div className="heroShort__contentContainer">
            <div className="heroShort__copy">
              <div
                className="heroShort__titleWrap"
                style={{
                  '--fontSizeAdjustment': `${titleFontAdjustment[currentBreakpoint]}`,
                }}
              >
                <h1
                  className="heroShort__title"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                {subTitle && (
                  <div
                    className="heroShort__subtitleWrap"
                    style={{
                      '--fontSizeAdjustment': `${subtitleFontAdjustment[currentBreakpoint]}`,
                    }}
                  >
                    <div
                      className="heroShort__subtitle"
                      dangerouslySetInnerHTML={{ __html: subTitle }}
                    />
                  </div>
                )}
              </div>
              {cta.useCTA && (
                <div className="heroShort__cta">
                  <CtaButton {...cta} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

HeroShort.propTypes = {
  background: PropTypes.string,
  customCssClass: PropTypes.string,
  titleFontAdjustment: FontAdjustmentPropTypes,
  subtitleFontAdjustment: FontAdjustmentPropTypes,
  heroRef: PropTypes.shape({ current: PropTypes.any }),
  images: WPImagesPropType,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  variation: PropTypes.string.isRequired,
  cta: CtaPropTypes,
};
