/**
 * @summary this file as all the questionnaire component mappings
 */
import { LogError } from 'utils/logging';
import BasicFormWizard from 'components/form-wizards/basic-form/BasicFormWizard';
import ClickPortalForm from 'components/form-wizards/click-portal-search/ClickPortalForm';
import ClickPortalSidebarForm from 'components/form-wizards/click-portal-search/ClickPortalSidebarForm';
import FullPageFormWizard from 'components/form-wizards/fullPageFormWrapper/FullPageFormWizard';
import { AVAILABLE_COMPONENTS as ac } from 'components/AVAILABLE_COMPONENTS';
import MultiQuestionFormWizardFullPage from 'components/form-wizards/multiQuestionFormWizard/MultiQuestionFormWizardFullPage';
import MultiQuestionFormWizard from 'components/form-wizards/multiQuestionFormWizard/MultiQuestionFormWizard';
import MicroPortalForm from './form-wizards/micro-portal/MicroPortalForm';
import AdditionalLeadsForm from './form-wizards/micro-portal/AdditionalLeadsForm';

/**
 * If we add a new form add its mapping below so we can render it via an API
 */
const COMPONENT_MAP = {
  [ac.BASIC_FORM_WIZARD]: BasicFormWizard,
  [ac.FULL_PAGE_FORM_WIZARD]: FullPageFormWizard,
  [ac.CLICK_PORTAL_FORM_WIZARD]: ClickPortalForm,
  [ac.MICRO_PORTAL_FORM_WIZARD]: MicroPortalForm,
  [ac.ADDITIONAL_LEADS_FORM]: AdditionalLeadsForm,
  [ac.CLICK_PORTAL_SIDEBAR_FORM_WIZARD]: ClickPortalSidebarForm,
  [ac.MULTI_QUESTION_FORM_FULL_PAGE]: MultiQuestionFormWizardFullPage,
  [ac.MULTI_QUESTION_FORM]: MultiQuestionFormWizard,
};

function getComponent(key) {
  if (!COMPONENT_MAP[key]) {
    LogError(`Questionnaire Not Found: ${key} is not a valid Component`);
    throw new Error(`Questionnaire Not Found: ${key} is not a valid Component`);
  }

  return COMPONENT_MAP[key];
}

export default getComponent;
