import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'utils/classnames';
import { capitalizeFirstLetter } from 'utils/stringHelpers';
import GlobalContext from 'hooks/contexts/GlobalContext';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import ResponsiveImage from 'components/base/responsiveImage/ResponsiveImage';
import DefaultErrorBoundary from 'components/error-boundaries/DefaultErrorBoundary';

import { WPImagesPropType } from 'utils/propTypes';
import AdmissionsDates from './AdmissionsDates';

const MEDIA_WIDTH_MAP = { small: '33vw', medium: '50vw', large: '100vw' };

const Admissions = (props) => {
  const {
    siteMeta: { startDates },
  } = useContext(GlobalContext);

  const {
    background,
    content,
    customCssClass,
    images,
    isReversed,
    subTitle,
    title,
    datesLabel,
    variation,
    mediaWidth,
    imageAlignment = 'Top',
  } = props;

  const responsiveImageSizes = {
    MOBILE: '100vw',
    TABLET: '100vw',
    DESKTOP: MEDIA_WIDTH_MAP[mediaWidth],
    DEFAULT: '100vw',
  };

  const getAdmissionsClassName = () => {
    const classes = {
      admissions: true,
      [`admissions--variation${
        variation ? capitalizeFirstLetter(variation) : ''
      }`]: !!variation,
      [`admissions--background${
        background ? capitalizeFirstLetter(background) : ''
      }`]: !!background,
      [`admissions--isReversed`]: isReversed,
      [`admissions--${customCssClass}`]: !!customCssClass,
      [`admissions--media${capitalizeFirstLetter(imageAlignment)}`]: true,
    };
    return classNames(classes);
  };

  return (
    <div className={getAdmissionsClassName()}>
      {variation === 'angled' && <span className="admissions__bkgrd" />}
      <div className="admissions__inner">
        {images?.image && images?.image?.url && (
          <div className="admissions__media">
            <DefaultErrorBoundary meta={{ component: 'ResponsiveImage' }}>
              <ResponsiveImage
                images={images}
                sizes={responsiveImageSizes}
                loading="lazy"
              />
            </DefaultErrorBoundary>
          </div>
        )}
        <div className="admissions__content">
          {title && <h1 className="admissions__title">{title}</h1>}
          {subTitle && <h2 className="admissions__subTitle">{subTitle}</h2>}
          {content && (
            <div className="admissions__copy">
              <Wysiwyg content={content} />
            </div>
          )}
        </div>
        <div className="admissions__dates">
          <AdmissionsDates startDates={startDates} label={datesLabel} />
        </div>
      </div>
    </div>
  );
};

export default Admissions;

Admissions.propTypes = {
  background: PropTypes.string,
  content: PropTypes.string,
  customCssClass: PropTypes.string,
  datesLabel: PropTypes.string,
  imageAlignment: PropTypes.string,
  images: WPImagesPropType,
  isReversed: PropTypes.bool,
  mediaWidth: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  variation: PropTypes.string,
};

Admissions.defaultProps = {};
