import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';

function Steps({ currentStepIndex, steps, stepsContainerRef }) {
  const stepsMap = steps.reduce(
    (transformedSteps, { id, title, heading, subTitle }) => {
      transformedSteps[id] = { id, title, heading, subTitle }; // eslint-disable-line no-param-reassign
      return transformedSteps;
    },
    {}
  );

  const getStepClass = (stepIndex) => {
    return cx({
      steps__step: true,
      'steps__step--isCurrent': stepIndex === currentStepIndex + 1,
      'steps__step--isComplete': stepIndex < currentStepIndex + 1,
    });
  };

  const stepComponent = (
    <ol className="steps">
      {Object.keys(stepsMap).map((step) => (
        <li
          key={`stepKeyIndex_${stepsMap[step].id}`}
          data-testid="step"
          className={getStepClass(stepsMap[step].id)}
        >
          <div className="steps__stepHeading" data-testid="stepHeading">
            <span className="steps__stepHeadingVal">
              {stepsMap[step].heading}
            </span>
          </div>
          <div className="steps__stepContent">
            {stepsMap[step].title && (
              <div className="steps__stepTitle" data-testid="stepTitle">
                {stepsMap[step].title}
              </div>
            )}
            {stepsMap[step].subTitle && (
              <div className="step__subTitle" data-testid="stepSubTitle">
                {stepsMap[step].subTitle}
              </div>
            )}
          </div>
        </li>
      ))}
    </ol>
  );

  // if no ref is passed then we'll render here
  if (!stepsContainerRef || !stepsContainerRef.current) {
    return stepComponent;
  }

  // if there's a ref, use it to render in a portal
  return createPortal(
    <ol className="steps">
      {Object.keys(stepsMap).map((step) => (
        <li
          key={`stepKeyIndex_${stepsMap[step].id}`}
          className={getStepClass(stepsMap[step].id)}
        >
          <div className="steps__stepHeading">
            <span className="steps__stepHeadingVal">
              {stepsMap[step].heading}
            </span>
          </div>
          <div className="steps__stepContent">
            <div className="steps__stepTitle">{stepsMap[step].title}</div>
            <div className="step__subTitle">{stepsMap[step].subTitle}</div>
          </div>
        </li>
      ))}
    </ol>,
    stepsContainerRef.current
  );
}

Steps.propTypes = {
  currentStepIndex: PropTypes.number,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      groupLabel: PropTypes.string,
      heading: PropTypes.string,
      title: PropTypes.string,
      subTitle: PropTypes.string,
      id: PropTypes.number,
    })
  ),
  stepsContainerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export default Steps;
