import { applyPreFills } from 'components/form-wizards/basic-form/utils';

export default function updatedFormValues(state, action) {
  const { updatedForm } = applyPreFills(state, {
    ...state.formValues,
    ...action.formValues,
  });

  const currentStepIndex = action.currentStepIndex || state.currentStepIndex;

  return {
    ...state,
    currentStepIndex,
    isLastStep: state.totalSteps === currentStepIndex + 1,
    formValues: updatedForm,
    formStatus: { ...state.formStatus, ...action.formStatus },
  };
}
