/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import cx from 'utils/classnames';
import { scrollTo } from 'utils/generalUtils';
import DefaultErrorBoundary from 'components/error-boundaries/DefaultErrorBoundary';
import useCSSsettings from 'hooks/custom/forms/useCSSsettings';
import ProgressBar from 'components/base/progressBar/ProgressBar';
import LoadingButton from 'components/base/buttons/loadingButton/LoadingButton';
import FormStep from 'components/form-wizards/basic-form/FormStep';
import FormContainerV1 from 'components/form-wizards/containers/FormContainerV1';
import { getTransitionInStatus } from './utils';

function Form(props) {
  const {
    currentAction,
    currentQuestions,
    currentStepIndex,
    formStatus,
    getNextButtonText,
    handleSubmit,
    isFirstStep = true,
    isFullPage,
    isNextDisabled,
    percentComplete,
    steps,
    stepsCount,
    title,
    variant,
  } = props;

  const [formStepHeight, setFormStepHeight] = useState(null);
  const [formIsTransitioning, setFormIsTransitioning] = useState(false);
  const wizardRef = useRef(null);
  const { formSpeed } = useCSSsettings(wizardRef);

  const stepContainerClass = cx({
    basicFormWizard__stepContainer: true,
    [`basicFormWizard__stepContainer--${currentAction}`]: currentAction,
    'basicFormWizard__stepContainer--isTransitioning': formIsTransitioning,
  });

  function handleOnEntering(node) {
    const heightOffset = 2; // offset here fixes a bug where the height jitters after the animation is complete
    setFormStepHeight(node.clientHeight + heightOffset);
    setFormIsTransitioning(true);

    if (formStatus.isDirty) {
      scrollTo(0);
    }
  }

  return (
    <div
      className={
        isFirstStep
          ? 'basicFormWizard basicFormWizard--isFirstStep'
          : 'basicFormWizard'
      }
      ref={wizardRef}
    >
      <div className="basicFormWizard__container">
        <CSSTransition
          in
          mountOnEnter
          appear
          timeout={formSpeed}
          className="basicFormWizard__innerContainer"
          classNames="basicFormWizard__innerContainer"
        >
          <div>
            {formStatus.formError && (
              <div className="basicFormWizard__formError">
                {formStatus.formError}
              </div>
            )}
            <h1 className="basicFormWizard__title">{title}</h1>
            <CSSTransition
              in={
                (!isFirstStep &&
                  (formStatus.isDirty ||
                    formStatus.isResumeSessionAcknowledged)) ||
                isFullPage
              }
              mountOnEnter
              unmountOnExit
              timeout={formSpeed}
              className="basicFormWizard__progressBar"
              classNames="basicFormWizard__progressBar"
            >
              <div>
                <ProgressBar
                  currentStepIndex={steps[currentStepIndex].trueStepIndex}
                  totalSteps={stepsCount}
                  progress={percentComplete}
                />
              </div>
            </CSSTransition>
            <TransitionGroup
              component="div"
              className={stepContainerClass}
              style={{ minHeight: formStepHeight }}
            >
              <CSSTransition
                in={getTransitionInStatus(formStatus, isFirstStep)}
                key={`formStep_${currentStepIndex}`}
                classNames="formStep"
                timeout={formSpeed}
                unmountOnExit
                onEntering={handleOnEntering}
                onExited={() => {
                  setFormIsTransitioning(false);
                }}
              >
                <DefaultErrorBoundary meta={{ currentStepIndex, variant }}>
                  <FormStep
                    questions={currentQuestions}
                    stepLabel={steps[currentStepIndex].groupLabel}
                    stepIndex={currentStepIndex}
                    formIsDirty={formStatus.isDirty}
                  />
                </DefaultErrorBoundary>
              </CSSTransition>
            </TransitionGroup>
            <div className="basicFormWizard__ctaContainer">
              {!isFirstStep &&
                (formStatus.isDirty ||
                  formStatus.isResumeSessionAcknowledged) && (
                  <button
                    type="button"
                    onClick={() => window.history.back()}
                    className="basicFormWizard__backBtn"
                    disabled={formIsTransitioning || formStatus.isSubmitting}
                  >
                    Previous
                  </button>
                )}
              <LoadingButton
                onClick={handleSubmit}
                className="basicFormWizard__nextBtn"
                disabled={formIsTransitioning || isNextDisabled}
                loading={isNextDisabled}
                text={getNextButtonText()}
              />
            </div>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
}

export default function BasicFormWizard(props) {
  return <FormContainerV1 {...props} Form={Form} />;
}
