import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'utils/classnames';
import { capitalizeFirstLetter } from 'utils/stringHelpers';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import { getProgramStartDates } from 'utils/startDateHelpers';
import { CtaPropTypes, WPImagesPropType } from 'utils/propTypes';
import GlobalContext from 'hooks/contexts/GlobalContext';
import CtaButton from 'components/blocks/ctaButton/CtaButton';
import ProgramInfoStats from './ProgramInfoStats';

const ProgramInfo = (props) => {
  const {
    associatedProgram,
    background,
    cta,
    customCssClass,
    isReversed,
    mediaWidth,
    variation,
  } = props;

  const {
    siteMeta: { startDates: globalStartDates },
  } = useContext(GlobalContext);

  const {
    title,
    description,
    totalCredits,
    format,
    courseLength,
    transferCredits,
    startDates,
    degreeTypeGuids,
  } = associatedProgram;

  const programInfoClassName = classNames({
    programInfo: true,
    [`programInfo--variation${
      variation ? capitalizeFirstLetter(variation) : ''
    }`]: !!variation,
    [`programInfo--background${
      background ? capitalizeFirstLetter(background) : ''
    }`]: !!background,
    [`programInfo--mediaWidth${
      mediaWidth ? capitalizeFirstLetter(mediaWidth) : ''
    }`]: !!mediaWidth,
    [`programInfo--isReversed`]: isReversed,
    [`programInfo--${customCssClass}`]: !!customCssClass,
  });

  if (isEmpty(associatedProgram)) {
    return null;
  }

  return (
    <div className={programInfoClassName}>
      <div className="programInfo__inner">
        <div className="programInfo__stats">
          <ProgramInfoStats
            stats={{ totalCredits, format, courseLength, transferCredits }}
            startDates={getProgramStartDates(
              startDates,
              globalStartDates,
              degreeTypeGuids
            )}
          />
        </div>

        <div className="programInfo__content">
          {title && <h1 className="programInfo__title">{title}</h1>}
          {description && (
            <div className="programInfo__copy">
              <Wysiwyg content={description} />
            </div>
          )}
          {cta && cta.useCTA && (
            <div className="programInfo__cta">
              <CtaButton {...cta} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgramInfo;

ProgramInfo.propTypes = {
  background: PropTypes.string,
  associatedProgram: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    degreeTypeGuids: PropTypes.array,
    totalCredits: PropTypes.number,
    format: PropTypes.string,
    courseLength: PropTypes.number,
    transferCredits: PropTypes.number,
    startDates: PropTypes.array,
  }),
  content: PropTypes.string,
  customCssClass: PropTypes.string,
  cta: CtaPropTypes,
  images: WPImagesPropType,
  imageAlignment: PropTypes.string,
  isReversed: PropTypes.bool,
  mediaWidth: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  variation: PropTypes.string,
};

ProgramInfo.defaultProps = {};
