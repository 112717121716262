import { useContext, useMemo } from 'react';
import GlobalContext from 'hooks/contexts/GlobalContext';

export function replaceTokens(text, tokenMap = {}) {
  // filter out any tokens that are improperly formatted
  // and create a single string to regex against
  const tokenStringsForRegExt = Object.keys(tokenMap)
    .filter((token) => token.match(/^{\w+}$/gi))
    .join('|');
  if (!tokenStringsForRegExt) {
    return text;
  }

  const tokensRegEx = new RegExp(tokenStringsForRegExt, 'gi');

  return text.replace(tokensRegEx, (match) => {
    return tokenMap[match];
  });
}

/**
 * Custom React hook to replace tokens in a given text string with values.
 *
 * @param {string} textString - The text string to replace tokens in
 * @param {Object} tokens - An object with keys as token strings and values as the replacement values. Default is an empty object. Example: {{TOKEN_NAME}: 'token value'}
 *
 * @return {string} The tokenized text
 * */
export default function useTokenReplacement(textString, tokens = {}) {
  const {
    siteMeta: { siteName },
  } = useContext(GlobalContext);

  const currentYear = useMemo(() => new Date().getFullYear(), []);

  const defaultTokenMap = {
    '{YEAR}': currentYear,
    '{SCHOOL_NAME}': siteName,
  };

  // merge default tokens with any passed in
  const currentTokens = { ...defaultTokenMap, ...tokens };

  const tokenizedText = useMemo(
    () => replaceTokens(textString, currentTokens),
    [textString, tokens]
  );

  return tokenizedText;
}
