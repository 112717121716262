import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import GlobalContext from 'hooks/contexts/GlobalContext';
import { FIELD_NAMES } from 'consts';
import getFormSelectionObjects from 'components/form-wizards/click-portal-search/utils/getFormSelectionObjects';
import getCurrentStepIndexFromSelection from 'components/form-wizards/click-portal-search/utils/getCurrentStepIndexFromSelection';
import Steps from './Steps';

function StepsWithSelections({ stepsContainerRef }) {
  const {
    CLICK_PORTAL_DEGREES,
    CLICK_PORTAL_CONCENTRATIONS,
    CLICK_PORTAL_SUBJECTS,
  } = FIELD_NAMES;

  const {
    microSiteTaxonomyMap,
    clickPortal: { currentSelection },
  } = useContext(GlobalContext);

  const { degree, category, subject } = getFormSelectionObjects(
    currentSelection,
    microSiteTaxonomyMap
  );

  const steps = [
    {
      id: 1,
      title: 'Choose your Degree Level',
      name: CLICK_PORTAL_DEGREES,
      subTitle: '',
      heading: '1',
      groupLabel: '',
    },
    {
      id: 2,
      title: 'Choose your Subject',
      name: CLICK_PORTAL_SUBJECTS,
      subTitle: '',
      heading: '2',
      groupLabel: '',
    },
    {
      id: 3,
      title: 'Choose your Concentration',
      name: CLICK_PORTAL_CONCENTRATIONS,
      subTitle: '',
      heading: '3',
      groupLabel: '',
    },
  ];

  function getStepLabel(stepName) {
    switch (stepName) {
      case CLICK_PORTAL_DEGREES:
        return degree?.label;
      case CLICK_PORTAL_CONCENTRATIONS:
        return category?.label;
      case CLICK_PORTAL_SUBJECTS:
        return subject?.label;

      default:
        return '';
    }
  }

  const nextSteps = steps.map((step) => {
    const stepLabel = getStepLabel(step.name);

    const nextTitle = stepLabel ? `You Chose ${stepLabel}` : step.title;
    return {
      ...step,
      title: nextTitle,
    };
  });

  return (
    <Steps
      currentStepIndex={getCurrentStepIndexFromSelection(currentSelection)}
      steps={nextSteps}
      stepsContainerRef={stepsContainerRef}
    />
  );
}

StepsWithSelections.propTypes = {
  stepsContainerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any,
    }),
  ]),
  formValues: PropTypes.shape({}),
};

export default StepsWithSelections;
