import { applyPreFills } from 'components/form-wizards/basic-form/utils';
import { categoryFieldIds, parentCategoryFieldIds } from 'consts';
import { LogError, LogInfo } from 'utils/logging';
import { getValidQuestions } from 'utils/formValuesUtils';
import moveToStep from './moveToStep';

/**
 * @summary this is used to update a form fields value
 * @param {Object} state - the current state
 * @param {String} action.name - the name of the field
 * @param {String|Object} action.value - the value of the field
 */
export default function updateFormValue(state, action) {
  const { updatedForm, questionsCleared } = applyPreFills(
    state,
    state.formValues,
    {
      [action.name]: action.value,
    }
  );

  const isDirty = state.formStatus.isDirty || !action.isNonUserAction;

  const updatedState = {
    ...state,
    currentQuestions: getValidQuestions(
      state.formConfigs.steps[state.currentStepIndex].questions,
      updatedForm,
      state.allQuestionsInForm,
      state.formStatus.dynamicOptions
    ),
    formValues: updatedForm,
  };

  // if isDirty or isFormResumeEligible Flag needs to be changed
  if (isDirty !== state.formStatus.isDirty) {
    updatedState.formStatus = {
      ...state.formStatus,
      isDirty,
    };
  }

  // TODO: SINCE CP WE NOW HAVE A CLEAR_VALUE DEPENDANCE THAT IS MORE GENERIC AND CAN HANDLE THIS USE CASE
  // if subject question is changed we should clear out the answer for concentration
  try {
    const isSubjectQuestion = parentCategoryFieldIds.includes(
      (state.fieldNameMap[action.name] || {}).id
    );
    if (isSubjectQuestion) {
      const catId = categoryFieldIds.find((id) => state.allQuestionsInForm[id]);
      const catFieldName = state.allQuestionsInForm[catId].name;
      const catVal = updatedState.formValues[catFieldName];
      if (catVal && !catVal.isPreFilled) {
        updatedState.formValues[catFieldName] = [];
        LogInfo(
          'BasicFormWizard updateFormValue has reset Concentrating as Subject was updated'
        );
      }
    }
  } catch (error) {
    LogError(`Failed to clear category value ${error.message}`);
  }

  // if values were cleared we should move user back to that step
  if (questionsCleared.length) {
    let lowestStepIndex = state.currentStepIndex;
    questionsCleared.forEach((question) => {
      const questionIndex = state.formConfigs.steps.findIndex((step) =>
        step.questions.includes(question)
      );
      if (questionIndex < lowestStepIndex) {
        lowestStepIndex = questionIndex;
      }
    });

    return moveToStep(updatedState, {
      ...action,
      nextStepIndex: lowestStepIndex,
    });
  }

  return updatedState;
}
