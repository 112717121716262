import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'utils/classnames';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import SectionTitle from 'components/blocks/headers/section-title/SectionTitle';
import { capitalizeFirstLetter } from 'utils/stringHelpers';

const UserContent = (props) => {
  const {
    background,
    customCssClass,
    variation,
    content,
    textAlignment,
    isReversed,
    title,
    subTitle,
  } = props;

  const getUserContentClassName = classNames({
    userContent: true,
    [`userContent--isReversed`]: isReversed,
    [`userContent--variation${
      variation ? capitalizeFirstLetter(variation) : ''
    }`]: !!variation,
    [`userContent--background${
      background ? capitalizeFirstLetter(background) : ''
    }`]: !!background,
    [`userContent--${customCssClass}`]: !!customCssClass,
  });

  return (
    <div className={getUserContentClassName}>
      <div className="userContent__inner">
        <div className="userContent__title">
          <SectionTitle title={title} />
        </div>
        {subTitle && <h2 className="userContent__subTitle">{subTitle}</h2>}
        <div className="userContent__content">
          <Wysiwyg
            content={content}
            isReversed={isReversed}
            textAlignment={textAlignment}
          />
        </div>
      </div>
    </div>
  );
};

export default UserContent;

UserContent.propTypes = {
  background: PropTypes.string,
  content: PropTypes.string,
  customCssClass: PropTypes.string,
  isReversed: PropTypes.string,
  subTitle: PropTypes.string,
  textAlignment: PropTypes.string,
  title: PropTypes.string,
  variation: PropTypes.string,
};

UserContent.defaultProps = {};
