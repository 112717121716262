/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import { isEmpty } from 'lodash';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useCSSsettings from 'hooks/custom/forms/useCSSsettings';
import FaqPanel from './FaqPanel';

function Faqs({ title, subTitle, content, faqs }) {
  const [currentTab, setCurrentTab] = useState(0);
  const [faqPanelsHeight, setFaqPanelsHeight] = useState(null);
  const [faqPanelsAreTransitioning, setFaqPanelsAreTransitioning] =
    useState(false);

  const { faqPanelAnimationSpeed } = useCSSsettings();

  function handleOnEntering(node) {
    const heightOffset = 2; // offset here fixes a bug where the height jitters after the animation is complete
    setFaqPanelsHeight(node.clientHeight + heightOffset);
    setFaqPanelsAreTransitioning(true);
  }

  const faqsPanelsClass = cx({
    faqs__panels: true,
    'faqs__panels--areTransitioning': faqPanelsAreTransitioning,
  });

  return (
    <div className="faqs">
      <div className="faqs__inner">
        <div className="faqs__content">
          <div className="faqs__title">
            <Wysiwyg content={title} />
          </div>
          {subTitle && (
            <div className="faqs__subTitle">
              <Wysiwyg content={subTitle} />
            </div>
          )}
          {content && (
            <div className="faqs__copy">
              <Wysiwyg content={content} />
            </div>
          )}
        </div>
        {!isEmpty(faqs) && (
          <div className="faqs__faqs" data-testid="faqs__faqs">
            <div className="faqs__nav">
              <div className="faqs__navTitle">FAQ Question Categories</div>
              <ul className="faqs__navItems" data-testid="faqs__navItems">
                {faqs.map((faq, index) => {
                  return (
                    <li
                      className={
                        currentTab === index
                          ? 'faqs__navItem faqs__navItem--isActive'
                          : 'faqs__navItem'
                      }
                      key={`faqs__navItem__${index}`}
                      data-testid={`faqs__navItem__${index}`}
                    >
                      <button
                        type="button"
                        className="faqs__navItemBtn"
                        onClick={() => setCurrentTab(index)}
                      >
                        <Wysiwyg content={faq.title} />
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>

            <TransitionGroup
              component="div"
              className={faqsPanelsClass}
              style={{ '--faqPanelHeight': `${faqPanelsHeight}` }}
            >
              <CSSTransition
                appear
                in={currentTab !== null}
                key={`faqs__panel__${currentTab}`}
                mountOnEnter
                unmountOnExit
                timeout={faqPanelAnimationSpeed}
                className="faqs__panel"
                classNames="faqs__panel"
                onEntering={handleOnEntering}
                onExited={() => {
                  setFaqPanelsAreTransitioning(false);
                }}
              >
                <div>
                  <FaqPanel
                    questions={faqs[currentTab]?.questions}
                    panelIndex={currentTab}
                  />
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
        )}
      </div>
    </div>
  );
}

Faqs.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  content: PropTypes.string,
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          question: PropTypes.string,
          answer: PropTypes.string,
        })
      ),
    })
  ),
};

export default Faqs;
