import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { scrollTo } from 'utils/generalUtils';
import Link from 'next/link';

function CardCTA({
  callToActionBehavior,
  programSlug,
  onClick,
  className: buttonClass,
  linkParam = '',
}) {
  const router = useRouter();

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13 || e.key === 'Enter') {
      onClick();
    }
  };

  const onScrollToFormHandler = (e) => {
    e.preventDefault();
    scrollTo(0);
    onClick();
  };

  const urlPath = router?.query?.degree || 'default';

  switch (callToActionBehavior) {
    case 'goToProgram':
      return (
        <Link
          href="/program/[pid]"
          as={`/program/${programSlug}`}
          passHref
          className={buttonClass}
          data-testid={`cardCTA--${callToActionBehavior}`}>
          {/* eslint-disable jsx-a11y/anchor-is-valid */}
          
            Request Info
          
        </Link>
      );
    case 'scrollToForm':
      return (
        <button
          data-testid={`cardCTA--${callToActionBehavior}`}
          type="button"
          onClick={onScrollToFormHandler}
          onKeyDown={onKeyDownHandler}
          className={buttonClass}
        >
          Request Info
        </button>
      );

    case 'fullPageForm': {
      /* eslint-disable no-fallthrough */
    }
    default:
      return (
        <a
          data-testid={`cardCTA--${callToActionBehavior}`}
          className={buttonClass}
          href={`/${urlPath}/landing/form${linkParam}`}
          onClick={onClick}
          onKeyDown={onKeyDownHandler}
        >
          Request Info
        </a>
      );
  }
}

CardCTA.propTypes = {
  callToActionBehavior: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  programSlug: PropTypes.string,
  linkParam: PropTypes.string,
};

CardCTA.defaultProps = {
  onClick: () => {},
};

export default CardCTA;
