import React from 'react';

const basicFormWizardContext = {
  formValues: {},
  formStatus: {},
  handleChange: () => {},
};

const BasicFormWizardContext = React.createContext(basicFormWizardContext);

export default BasicFormWizardContext;
