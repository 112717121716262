import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'components/base/selects/base-select/ReactSelect';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import Rating from 'components/base/rating/Rating';
import Checkbox from 'components/base/checkbox/Checkbox';

import { OptionPropType, OptionsPropType } from 'utils/propTypes';
import SchoolLogo from '../SchoolLogo';

function SelectionCard({
  options,
  onChange,
  name,
  label,
  meta: { schoolLogo, rating, description, impressionGuid },
  value = { label, impressionGuid }, // default value to have label for tcpa and impressionGuid for onSubmit
}) {
  function _onCheckBoxChange() {
    // adding label for TCPA display
    const nextSelection = { ...value, isSelected: !value?.isSelected };
    onChange(nextSelection, { name });
  }

  function _onSelectionChange(nextVal) {
    const nextSelection = { ...value, ...nextVal };
    onChange(nextSelection, { name });
  }

  return (
    <div className="selectionCard">
      <div className="selectionCard__body">
        <div className="selectionCard__logo">
          <SchoolLogo
            logo={schoolLogo}
            className="selectionCard__logoImg"
            alt={`${label} logo`}
          />
        </div>
        <div className="selectionCard__content">
          <div className="selectionCard__checkbox">
            <label
              htmlFor={`selectionCard__${name}`}
              className="selectionCard__label isVisuallyHidden"
            >
              {label}
            </label>
            <Checkbox
              id={`selectionCard__${name}`}
              onChange={_onCheckBoxChange}
              name={name}
              value={value?.isSelected}
            />
          </div>
          <h4 className="selectionCard__title">{label}</h4>
          <div className="selectionCard__rating">
            <Rating rating={rating} id={`rating${name}`} />
          </div>
          <div className="selectionCard__description">
            <Wysiwyg content={description} />
          </div>
        </div>
      </div>
      {value?.isSelected && (
        <div className="selectionCard__footer">
          <div className="selectionCard__formCtrl">
            <div className="selectionCard__formLabel">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="selectCardSelection">Choose your program</label>
            </div>
            <div className="selectionCard__formField">
              <ReactSelect
                options={options}
                value={value?.value}
                onChange={_onSelectionChange}
                isDisabled={!value?.isSelected}
                name="selectCardSelection"
                id="selectCardSelection"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

SelectionCard.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  fieldRef: PropTypes.shape({}),
  options: PropTypes.oneOfType([
    OptionsPropType,
    PropTypes.arrayOf([PropTypes.string, PropTypes.number]),
  ]).isRequired,
  value: PropTypes.oneOfType([
    OptionPropType,
    PropTypes.string,
    PropTypes.number,
  ]),
  meta: PropTypes.shape({
    description: PropTypes.string,
    impressionGuid: PropTypes.string,
    schoolLogo: PropTypes.shape({
      mobile: PropTypes.string,
      desktop: PropTypes.string,
    }),
    rating: PropTypes.number,
  }),
};

SelectionCard.defaultProps = {
  onChange: () => {},
};

export default SelectionCard;
