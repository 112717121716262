import React, { useRef, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';
import GlobalContext from 'hooks/contexts/GlobalContext';
import debounce from 'lodash/debounce';
import { CSSTransition } from 'react-transition-group';
import useOutsideClick from 'hooks/custom/useOutsideClick';
import useCSSsettings from 'hooks/custom/forms/useCSSsettings';

const TOOLTIP_OFFSET = 10;

const ToolTip = ({
  children,
  label,
  positionOnMobile = false,
  openOnHover = false,
}) => {
  const { windowSize } = useContext(GlobalContext);
  const [isVisible, setIsVisible] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({});
  const toolTipRef = useRef(null);
  const { toolTipAnimationEnterSpeed, toolTipAnimationExitSpeed } =
    useCSSsettings();

  function handleShowHide(toolTipIsVisible) {
    return debounce(() => setIsVisible(toolTipIsVisible), 100);
  }

  const handleOutsideClick = useCallback(() => {
    if (isVisible) {
      setIsVisible(false);
    }
  }, [isVisible]);

  useOutsideClick(toolTipRef, handleOutsideClick);

  const isMobile = windowSize.currentBreakpoint === 'mobile';

  function getToolTipPosition(contentRef) {
    const toolTipContentRef = contentRef;
    let height;
    if (toolTipContentRef) {
      height = toolTipContentRef.scrollHeight;
    }
    if ((isMobile && !positionOnMobile) || !toolTipRef.current) {
      return setToolTipPos({ '--toolTipHeight': `${height}px` });
    }

    const toolTipContentPositions = {};

    const toolTipWidth = toolTipRef.current.offsetWidth;
    const toolTipLeft = toolTipRef.current.getBoundingClientRect().left;

    toolTipContentPositions.width =
      toolTipContentRef.offsetWidth > windowSize.width
        ? windowSize.width - TOOLTIP_OFFSET * 2
        : toolTipContentRef.offsetWidth;

    toolTipContentPositions.leftSidePos =
      toolTipLeft + toolTipWidth / 2 - toolTipContentPositions.width / 2;
    toolTipContentPositions.rightSidePos =
      toolTipLeft + toolTipWidth / 2 + toolTipContentPositions.width / 2;

    toolTipContentPositions.left =
      -(toolTipContentPositions.width / 2) + toolTipWidth / 2;

    // reposition if the tooltip goes off the right side of the page
    if (toolTipContentPositions.rightSidePos > windowSize.width) {
      toolTipContentPositions.left =
        -(toolTipContentPositions.width / 2) +
        toolTipWidth / 2 -
        (toolTipContentPositions.rightSidePos - windowSize.width) -
        TOOLTIP_OFFSET;
    }

    // reposition if the tooltip goes off the left side of the page
    if (toolTipContentPositions.leftSidePos < 0) {
      toolTipContentPositions.left =
        -(toolTipContentPositions.width / 2) +
        toolTipWidth / 2 -
        toolTipContentPositions.leftSidePos +
        TOOLTIP_OFFSET;
    }

    // distance from the left side of the tooltip to the center of the trigger
    const triggerOffset =
      (toolTipContentPositions.left - toolTipWidth / 2) * -1;

    return setToolTipPos({
      left: `${toolTipContentPositions.left}px`,
      width: `${toolTipContentPositions.width}px`,
      '--toolTipHeight': `${height}px`,
      '--toolTipTriggerOffset': `${triggerOffset}px`,
    });
  }

  const toolTipClass = cx({
    toolTip: true,
    'toolTip--positionOnMobile': positionOnMobile && isMobile,
    'toolTip--isVisible': isVisible,
  });

  return (
    <span className={toolTipClass} role="tooltip">
      <button
        type="button"
        className="toolTip__trigger"
        ref={toolTipRef}
        onMouseEnter={openOnHover && !isMobile ? handleShowHide(true) : null}
        onMouseLeave={openOnHover && !isMobile ? handleShowHide(false) : null}
        onFocus={openOnHover && !isMobile ? handleShowHide(true) : null}
        onBlur={!isMobile ? handleShowHide(false) : null}
        onClick={!openOnHover || isMobile ? handleShowHide(!isVisible) : null}
      >
        <span className="toolTip__triggerText">{label}</span>
      </button>

      <CSSTransition
        in={isVisible}
        className="toolTip__content"
        classNames="toolTip__content"
        timeout={{
          enter: toolTipAnimationEnterSpeed,
          exit: toolTipAnimationExitSpeed,
        }}
        aria-hidden={!isVisible}
        onEnter={(nodeRef) => getToolTipPosition(nodeRef)}
        mountOnEnter
        unmountOnExit
      >
        <div style={toolTipPos}>
          <div className="toolTip__inner">
            {isMobile && (
              <button
                type="button"
                className="toolTip__closeBtn"
                onClick={isMobile ? handleShowHide(!isVisible) : null}
              >
                <span className="toolTip__closeBtnText">Close</span>
              </button>
            )}
            <div className="toolTip__contentText">{children}</div>
          </div>
        </div>
      </CSSTransition>
    </span>
  );
};

ToolTip.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  positionOnMobile: PropTypes.bool,
  openOnHover: PropTypes.bool,
};

export default ToolTip;
